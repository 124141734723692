@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;500;700&display=swap');

@keyframes dynamicMovement {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(5px);
  }
}

.DynamicText {
  background: linear-gradient(to left, #f7b801, #ff9e00);
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
  display: inline-block;
  animation: dynamicMovement 1s ease-in-out infinite;
}

.GradientText {
  background: linear-gradient(to right, #56CCF2, #2F80ED);
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
}
