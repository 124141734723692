@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');



h6 {
  color: #1f1f1f;
  margin: 0px 0 0 0;
  padding: 0;
}

h2 {
  color: #212529;
  margin: 12px 0 0 0;
  padding: 0;
}

.pageHeader {
  font-size: 22pt;
  color: #1f1f1f;
  margin: 20px 0 0 40px;
  padding: 0;
  font-weight: 900;
  font-family: 'Merriweather', serif;
}

.headerAndButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  /* background-color: #556cd6; */
}

.NewsEditorContainer {
  min-height: 100vh;
  margin: auto;
  color: black;
  display: flex;
  flex-direction: column;
}

.SimHeader {
  min-height: 48pt;
  /* width: auto; */
}

.HeaderBarContainer {
  width: 100vw;
  display: flex;
  align-content: center;
  /* background-color: aqua; */
  justify-content: center;
  align-items: center;
  margin: auto;
  border-bottom: 1px solid #383838;
}

.mainContent {
  padding: 20px;
}

.NewsEditorContent {
  display: flex;
  flex-direction: row;
  /* flex-grow: 1; */
  justify-content: space-between;
  /* overflow: auto; */
  /* margin: auto; */
}

.newsEditorEditor {
  width: 600px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 4px;
  /* justify-content: space-between; */
}

.newsEditorPreview {
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  /* background-color: cadetblue; */
  align-items: center;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  /* flex: 2; */
}

input, textarea, button {
  margin: 0 0 12px 0;
}

button {
  display: inline-block;
  color: #FFFFFF;
  background-color: #556cd6;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 12px;
  cursor: pointer;
  /* background-color: #fff; */
  border: 1px solid #556cd6;
  border-radius: 4px;
  margin: 10px 0;
}

.phonePreview {
  font-family: 'Roboto', sans-serif;

  border-radius: 12px;
  margin: 0 0pt 0 0pt;
  box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.3);
  text-align: left;
  height: 480pt;
  width: 240pt;
  display: flex; /* Use flexbox to arrange children */
  flex-direction: column; /* Stack children vertically */
  /* justify-content: space-between; */
  /* align-items: center; */
  overflow: hidden;
  background-color: #fff;
  font-size: calc(10px + 2vmin);
  color: black;
  padding-bottom: 20px; /* Padding at the bottom */
  margin-top: 16px;
}

.storyContentContainer {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.storyContentContainer::-webkit-scrollbar {
  display: none;
}

.storyContentContainer::-webkit-scrollbar {
  display: none;
}

.previewTextContent {
  padding: 12pt;
}

.previewStoryTag {
  color: #f77f00;
  font-size: 10pt;
  font-weight: 700;
  margin: 0;
  padding: 0 0 2pt 0;
}

.previewStoryHeadline {
  color: #383838;
  font-size: 16pt;
  font-weight: 800;
  margin: 0;
  padding: 0 0 12pt 0;
}

.previewStoryBody {
  color: #383838;
  font-size: 10pt;
  font-weight: 400;
  margin: 0;
  padding: 0;
  line-height: 2;
}

.newsStoryHeaderImage {
  width: 100%;
  height: 180pt;
  object-fit: cover;
}

/* Custom TextField styling */
.custom-team-name-field {
  margin-bottom: 12px;
  width: 100%;
}

.custom-team-name-input {
  /* width: auto; */
  flex: 1;
  height: 50px; /* Set the height of the input */
  padding: 0 14px;
  border: 1px solid #ccc; /* Add a border */
  border-radius: 4px; /* Round the corners */
  font-size: 16px; /* Set the font size */
  margin: 10px 0;
}

/* Adjust the label positioning */
.custom-team-name-label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #666;
}

/* Style focused state */
.custom-team-name-input:focus {
  border-color: #556cd6;
  outline: none;
}



input {
  font-size: 20;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  /* Additional styling here */
}

.custom-file-upload:hover {
  background-color: #e8e8e8;
}

.hidden-input {
  display: none;
}

.editor-toolbar {
  background-color: #f1f1f1;
}

.editor-toolbar a,
.editor-toolbar a i {
  color: #282828; /* Replace with your desired color */
}

.editor-toolbar a:hover,
.editor-toolbar a:hover i {
  color: #e5e5e5; /* Color on hover */
}

.editor-toolbar button {
  color: #212529;
}

.ImageAndTag {
  display: flex;
  flex-direction: row;
}

.newsEditorSectionContainer {
  display: flex;
  height: 50px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  /* background-color: #556cd6; */
  margin-bottom: 20px;
}

.newsEditorSectionContainerLabel {
  width: 120px;
}


.spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #ffffff;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.newsEditorEditor {
  /* width: 400pt; */
  height: calc(100vh - 64pt - 40px - 40px - 1px);
  color: #ffffff;
  background-color: #e5e5e5;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  overflow: hidden;
  margin: 20px;
  border-radius: 4px;
  flex: 2;
}

.newsEditorEditorLeft {
  width: 280pt;
  height: 100%;
  color: #ffffff;
  background-color: #e5e5e5;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  overflow: hidden;
  margin: 20px;
  border-radius: 4px;
}



.SimpleMDEEditor {
  flex: 1;
}

.simplemde-wrap {
  font-size: 12pt;
}