@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f3f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes gradient-bg {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.gradient-text {
  background: linear-gradient(45deg, #f472b6, #9333ea, #3b82f6, #6ee7b7);
  background-size: 400% 400%;
  animation: gradient-bg 6s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block; /* Ensures the gradient spans the text only */
}


.animated-background {
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, #6ee7b7 20%, transparent 20%),
              radial-gradient(circle, #9333ea 20%, transparent 20%),
              radial-gradient(circle, #f472b6 20%, transparent 20%);
  background-size: 300px 300px;
  animation: moveBlobs 10s linear infinite;
  position: relative;
  background-position: 0 0, 150px 150px, 300px 300px;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

@keyframes moveBlobs {
  0%, 100% {
    background-position: 0 0, 150px 150px, 300px 300px;
  }
  50% {
    background-position: 300px 300px, 0 0, 150px 150px;
  }
}

@keyframes gradient-bg {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@layer utilities {
  .animated-gradient-bg {
    background: linear-gradient(45deg, #f472b6, #9333ea, #3b82f6, #6ee7b7);
    background-size: 400% 400%;
    animation: gradient-bg 6s ease infinite;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}