@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;500;700&display=swap');


h6 {
  margin: 12pt 0 12pt 0;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #383838;
}

.standardButton {
  width: fit-content;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  padding: 12px 24px;
  font-family: 'Ubuntu', sans-serif;
  outline: none;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  display: flex;
  opacity: 1;
  align-items: center;
  margin: 0;
}

.standardButton:hover {
  opacity: 0.8;
  transform: scale(1.02);
}

.standardButton:disabled {
  opacity: 0.6;
  /* background-color: #e5e5e5; */
  /* border: 1px solid #e5e5e5; */
  transform: scale(1);
  cursor: default;
}

.whiteButton {
  background-color: #FFFFFF;
  border: 1px solid #757775;
  color: #1f1f1f;
}

.greenButton {
  background-color: #a7c957;
  border: 1px solid #a7c957;
  color: #1f1f1f;
}

.blueButton {
  background-color: #14213d;
  border: none;
  color: #ffffff;
}

.orangeButton {
  background-color: #f48c06;
  border: none;
  color: #ffffff;
}

.HeaderContainer {
  width: 80vw;
  height: 72pt;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.spacer {
  flex-grow: 1;
}

a {
  color: #383838;
  text-decoration: none;
}

.studioContainer {
  display: flex;
  flex-direction: row;
}

.SimHome {
  border-radius: 12px;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.2);
  /* border: 8px solid #ffffff; */
}

.leftSide {
  width: 240pt;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
}

.rightSide {
  width: 240pt;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.colourCube {
  width: 36pt;
  height: 36pt;
}

.signInWithGoogle {
  cursor: pointer;
}


.standardTextField {
  width: calc(50% - 16px - 14px - 10px);
  height: 50px;
  padding: 0 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin: 10px 0;
}

/* Style focused state */
.standardTextField:focus {
  border-color: #556cd6;
  outline: none;
}





#colorPicker {
  box-sizing: border-box;
  border: 0px solid transparent;
  border-radius: 12px;
  margin: 0;
  padding: 0;
  width: 64px;
  height: 64px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

input[type="color"] {
  border-radius: 12px;
  padding: 0;
  border: 5px solid #DDD;
}

input[type="color"]::-moz-color-swatch {
  border: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}


.playsetCheckbox {
  /* accent-color: #a7c957; */
  width: 24px;
  height: 24px;
  margin: 0;
}

/* .checkbox-label {
  cursor: pointer;
} */


.gradientBlob {
  width: 86%;
  height: 86%;
  z-index: 0;
  border-radius: 15rem;
  background: radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(ellipse at 70% 60%, #c3e060 0%, rgba(195, 224, 96, 0) 90%), radial-gradient(ellipse at 30% 30%, #c3e060 0%, rgba(195, 224, 96, 0) 60%), radial-gradient(ellipse at bottom left, #00a3cb 0%, rgba(0, 163, 203, 0) 70%), linear-gradient(135deg, rgba(18, 46, 119, 0) 0%, rgba(18, 46, 119, 0) 75%, #122e77 100%), linear-gradient(to right, #625793 0%, #d55d64 35%, #e49129 65%, #c0671c 100%);
  background-blend-mode: screen, luminosity, saturation, darken, color-dodge, color;
  filter: blur(50px);
}

.tilt-image {
  transition: box-shadow 0.1s, transform 0.1s;
}

.tilt-image:hover {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}