@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');


h2 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24pt;
}

h3 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18pt;
  color: #1f1f1f;
  opacity: 90%;
  margin: 0;
}

.Dashboard {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  width: 100%;
  min-height: 100vh;
  background-color: #fefefe;
}

.dashboardTopSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.dashboardTopSectionOverview {
  width: 80vw;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.appInfoBoxContent {
  width: 80vw;
  max-width: 1200px;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px dashed #1f1f1f;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
}

.appInfoBoxContentRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px 0 20px;
}

.appInfoBoxContentRow .standardButton {
  margin-right: 20px;
}

.dashboardTopSectionText {
  display: flex;
  flex-direction: column;
}

.dashboardTopSectionText h2 {
  margin-bottom: 28px;
}

.appIcon {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  margin-right: 16px;
}